/*App.css*/
.App {
  text-align: center;
}

.app-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto; /* Allow horizontal scrolling for the entire app */
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-top: 60px; /* Adjust the value according to the height of your navbar */
  overflow-x: hidden; /* Prevent horizontal scroll inside main content itself */
  min-width: 0; /* Ensures that flex children don't force the container to expand */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.react-calendar {
  max-width: none !important; /* Remove any max-width constraints */
  width: 100% !important;
}

.bookedDate {
  background-color: #ffcccb !important;
}

.dataGridContainer {
  display: flex;
  justify-content: center;
  overflow-x: auto; /* Makes the container scrollable horizontally */
  width: 100%;
}

.pinnedAndScrollableGrids {
  display: flex;
  max-width: calc(100% - 40px); /* Adjust the max-width as necessary */
  margin: 0 auto; /* Center the grids container */
}

.pinnedGrid,
.scrollableGrid {
  min-width: 300px; /* Adjust based on your grid's content */
}

.scrollableGrid {
  overflow-x: auto; /* Makes only the scrollable part of the grid scrollable horizontally */
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor {
  padding: 0 0px;
}

.booking-1 { background-color: #ffcccc; }
.booking-2 { background-color: #ccffcc; }
.booking-3 { background-color: #ccccff; }
