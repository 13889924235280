.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 400px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.form-field {
    margin-bottom: 16px;
}

.login-button {
    margin-top: 24px;
    padding: 10px 0;
    background-color: #1976d2;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #115293;
}

.error-message {
    color: #d32f2f;
    margin-bottom: 16px;
}
