/* BuchungenPage.css */

.pageContainer {
    padding: 20px;
  }
  
  .addButton {
    margin-bottom: 20px; /* Ensure there's space between the button and the date pickers */
  }
  
  .datePickersContainer {
    display: flex;
    padding: 10px;
    margin-bottom: 20px; /* Add space between date pickers */

  }
  
  .dataGridContainer {
    margin-top: 20px;
  }
  
  /* Additional styling for DatePicker and DataGrid */
  .MuiPickersToolbar-toolbar {
    background-color: #2196f3;
  }
  
  .MuiPickersToolbarButton-toolbarBtn {
    color: white;
  }
  
  .MuiDataGrid-root {
    font-family: Arial, sans-serif;
  }
  
  .MuiDataGrid-cell {
    padding: 8px;
    text-align: center;
  }
  
  .MuiDataGrid-row:nth-of-type(even) {
    background-color: #f0f0f0;
  }
  
  .MuiDataGrid-row:hover {
    background-color: #e0e0e0;
  }
  