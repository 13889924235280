/* Navbar.css */
.navbar {
    background-color: #1976d2; /* Example blue background */
    color: white;
    padding: 10px 20px;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-weight: bold;
  }
  
  .navbar a:hover {
    text-decoration: underline;
  }
  
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .nav-item {
    margin-left: 20px;
  }
  